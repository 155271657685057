import React from 'react'

export default function ({className}) {
  return (
    <svg
      viewBox="0 0 899 578"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M159.6,268.7c0.9-2.7,2.8-4.9,5.2-6.4c2.5-1.3,5.4-1.7,8.2-1.3c-0.9-0.9-2-0.9-3.2-1c-4.9-0.4-8.2,1.9-10.1,6.2
	c-2.8-5.5-5.9-6.4-10.7-6.1c-0.9-0.1-1.7,0.2-2.3,0.9h2c2-0.1,4.1,0.2,5.9,1c2.2,0.9,3.9,2,4.5,4.5
	C159.2,267.2,159.3,268,159.6,268.7z M178.7,276.2h0.3c1.2-3.6,4-4.8,7.5-4.9c-1-0.7-2.1-1-3.3-0.9c-2,0.1-3.7,1.5-4.4,3.3
	c-2-2.8-2.5-2.6-3.2-2.9c-1.4-0.6-3.6-0.3-4,0.4l4.3,0.9c1.5,0.3,2.6,1.6,2.6,3.2C178.5,275.8,178.7,275.9,178.7,276.2z"
      />
      <path
        d="M218.6,189.9c0.1,0,0.4,0.1,0.6,0.1c3.6,0.4,7.4,0.7,11,1.2c4.5,0.6,8.9,1.6,13.2,2.9c2.6,0.9,5.2,1.7,7.7,2.8
	c1.2,0.5,2,1.5,2.2,2.8c-0.1,1.3-1,2.4-2.3,2.8c-0.4,0.1-0.9,0.1-1.3,0c-2.8-0.9-5.5-1.9-8.4-2.8c-3.5-1-7-1.9-10.6-2.5
	c-3.3-0.6-6.7-0.9-10-1.2c-1.7-0.1-3.6-0.1-5.4-0.1c-1.5-0.1-3-0.1-4.5,0c-3.8,0.3-7.5,0.6-11.3,1c-5.9,0.8-11.6,2.1-17.2,4
	c-4.7,1.6-9.2,3.6-13.6,5.8c-5.2,2.7-10.1,5.9-14.8,9.5c-2.2,1.6-4.2,3.4-6.1,5.4c-1.4,1.3-2.9,2.9-4.3,4.3c-3.1,3.2-6,6.6-8.5,10.3
	c-3.3,4.6-6.1,9.4-8.5,14.5c-2.6,5.3-4.6,10.9-6.1,16.6c-0.8,3-1.4,6-1.9,9c-0.4,2.9-0.7,5.8-1,8.7c-0.3,3-0.4,6.2-0.4,9.3
	c0.2,3.6,0.3,7.1,0.7,10.7c0.6,4.6,1.6,9.2,2.9,13.6c1.6,5.4,3.6,10.7,5.9,15.8c0.2,0.3,0.3,0.4,0.3,0.6c4.3-1.2,8.4-2.3,12.7-3.3
	c3-0.7,6.1-1.3,9.1-1.7c3.3-0.4,6.7-0.9,10-1.2c2.3-0.3,4.6-0.3,6.8-0.4c3.3-0.1,6.7-0.1,10-0.1c2.7,0,5.4,0.1,8.1,0.4
	c2.6,0.1,5.4,0.4,8,0.7s5.4,0.7,8.1,1.2l6.5,0.9l6.1,0.9l5.9,0.9c2.3,0.3,4.8,0.7,7.1,1c2,0.3,4,0.6,5.9,0.9s3.9,0.6,5.8,0.9
	s4,0.6,5.9,0.9l6.2,0.9c1.4,0.1,3,0.4,4.5,0.6v-4c0-0.3-0.1-0.4-0.1-0.7c-0.1-2.2-0.1-4.3-0.1-6.5c-0.1-3-0.4-5.9-0.6-9
	c-0.1-2.3-0.3-4.6-0.6-7c-0.3-3.2-0.7-6.4-1.1-9.5c-0.4-2.8-0.7-5.6-1.2-8.4s-1-5.4-1.4-8.1c-0.7-3.5-1.3-7-2-10.4
	c-0.7-4.3-1.7-8.6-3-12.7c-0.4-1.2-0.8-2.4-1-3.6c-0.2-0.8-0.9-1.4-1.7-1.6c-2.3-0.3-4.4-1.6-5.6-3.6c-0.2-0.2-0.4-0.3-0.6-0.4
	c-1.1,1.3-2.2,2.6-3.2,3.9c-1.6,1.9-3.2,3.9-4.6,5.8c-1,1.3-2,2.6-3,3.8c-0.6,0.7-0.7,0.9-1.6,0.6c-0.1-1.6-0.3-3.2-0.4-4.9
	c-1.1,0.4-1.9,1.4-2.2,2.6c-1,4.3-2,8.7-3.2,13c-1,3.7-3.1,7-6.1,9.4c-0.7,0.6-1.4,1.1-2.2,1.6c-0.9,0.6-1.4,0.3-1.7-0.7
	c-0.1-0.7-0.3-1.5-0.4-2.3c0-0.3-0.1-0.5-0.3-0.7h-0.3c-0.3,0.9-0.5,1.8-0.6,2.8c0.2,3.8-0.7,7.6-2.6,11c-1.8,3.4-4.4,6.4-7.4,8.8
	c-0.6,0.4-1,0.9-1.6,1.3s-1,0.4-1.6,0.7c0-0.9,0.1-1.6,0.1-2.5c0.4-5.8,1.3-11.6,2.8-17.2c1.3-4.2,2.6-8.4,4.2-12.6
	c1.9-4.9,4.4-9.7,7.4-14c2.5-3.5,5.4-6.7,8.7-9.4c3-2.5,6.1-4.6,9.3-6.8c2-1.3,4.2-2.5,6.2-3.6c1.3-0.7,2.5-1.5,3.8-2.2
	c0-0.1,0-0.1-0.1-0.3c-1.7,0.1-3.5,0.1-5.4,0.3c-1.2,0.1-2.4,0.1-3.6,0c-0.9,0-0.7-0.9-0.7-1.5l0.4-1.7c-0.1-0.1-0.3-0.1-0.3-0.3
	c-1,0.9-2.2,1.6-3.2,2.5c-2.6,2.8-5.9,4.8-9.6,5.8c-1.9,0.4-3.8,0.7-5.8,1c-0.7,0.1-1.4,0.2-2.2,0.1c-3,0-2.8-1.2-2.6-3.3
	c0.1-1.1,0.3-2.3,0.4-3.6c-0.1,0-0.1-0.1-0.3-0.1c-1,1.3-2,2.6-2.9,3.9c-1.1,2-3,3.5-5.2,4.2c-1.7,0.3-3.5,0.3-5.2,0.1
	c-1.9-0.1-3.9-0.6-5.8-0.9s-4.2-0.7-6.2-1c-0.4,0-0.7-0.1-1.3-0.1c0.1-0.3,0.1-0.4,0.3-0.6c2.6-2.9,5.1-5.8,7.8-8.5
	c3.6-3.8,7.7-7,12.2-9.5c2.2-1.1,4.6-1.8,7.1-1.9c5.2-0.4,10.1,1,15,2.3c5.8,1.6,11.6,3.6,17.4,5.4c0.6,0.2,1.3,0.4,2,0.4l-4.3-3.5
	c-1.8-1.4-2.6-3.7-2-5.9c0.1-0.4,0.1-0.9,0.3-1.6c-1.2,0.1-2,0.1-3,0.3l-3.5,0.4c-3.1,0.1-6.1-0.5-8.8-2l-3.5-1.7
	c-1.1-0.6-1.3-1.3-0.7-2.3c0.2-0.4,0.4-0.8,0.6-1.2c0.2-0.5,0.4-1,0.7-1.5c-1.3,0.6-2.6,1-3.8,1.6c-1.1,0.6-2.3,1.1-3.5,1.6
	c-1.9,0.6-3.6,0-5.4-0.6c-2.2-0.9-4.3-1.6-6.5-2.6c-0.3-0.1-0.4-0.4-0.4-0.7c0-0.3,0.3-0.6,0.4-0.7c4.6-1.9,9.3-4,14-5.6
	c2.9-1.1,6-1.8,9.1-1.9c2.5,0.1,4.9,1.1,6.8,2.8c4.9,4.2,8.8,9.4,12.4,14.8c1.2,1.7,2.2,3.5,3.3,5.2c0.2,0.3,0.4,0.6,0.7,0.9
	c2.3-3.9,4.5-7.7,6.8-11.3c2-3,4-5.9,6.2-8.8c1.6-1.9,3.3-3.8,5.1-5.5c1.3-1.4,3-2.5,4.8-3.2c1.5-0.4,3.1-0.5,4.6-0.1
	c3.5,0.9,6.8,1.9,10.1,3c2.6,0.9,5.2,2,7.8,3.2c3.3,1.4,6.5,3,9.7,4.5c0.3,0.1,0.6,0.1,0.9,0.3l-0.4,0.4c-2.5,2.3-5.4,3.3-8.4,4.5
	c-1.6,0.6-3.2,0.7-4.5-0.3c-1.2-1-2.3-2.2-3.3-3.5c-0.1-0.1-0.1-0.3-0.3-0.4c-0.1-0.1-0.2,0-0.3,0.1c-0.2,0.4-0.3,0.9-0.3,1.3
	c0,0.8-0.7,1.4-1.5,1.4c-0.1,0-0.3,0-0.4-0.1c-3-1-5.9-2-9-3c-0.3-0.1-0.6-0.2-0.9-0.1c-0.9,0-1.2,0.4-0.9,1.2
	c0.5,0.8,0.6,1.8,0.3,2.8c-0.1,0.3-0.3,0.5-0.4,0.7c-1.7,1.7-3.7,3.1-5.9,4c-1.3,0.5-2.8,0.6-4.2,0.3c-2.3-0.6-4.7,0.7-5.5,3
	c-0.5,1.4-1.5,2.5-2.8,3.3c-1.9,1.2-3,2.8-2.6,5.2c0.1,0.9,0.3,1.6,0.4,2.5c1.7-1.5,3.5-2.8,5.2-4c4.4-3.6,9-6.9,13.8-9.9
	c2.6-1.7,5.5-2.9,8.5-3.8c2.8-0.7,5.8-0.5,8.4,0.7c3.4,1.5,6.6,3.5,9.4,5.9c3.6,2.9,6.9,5.9,10.4,9c2,1.7,3.9,3.6,5.8,5.5l0.7,0.7
	c-2,1.2-3.8,2.3-5.9,2.6c-1.3,0.1-2.6,0.1-3.9,0h-0.6c-1.7,0.9-3.5,0.6-5.3,0.3c-1.1-0.2-2.1-0.2-3.2-0.1c-1.7,0.1-2.3-1.2-2.9-2.3
	c-0.5-1.3-0.9-2.6-1.3-3.9c-0.4-1.1-1-2.5-1.6-3.8c-0.9,1-0.7,2-0.9,3c0,0.8,0,1.6-0.1,2.3c-0.3,1.1-0.7,1.4-1.9,1.1
	c-0.5-0.2-0.9-0.4-1.3-0.7c-0.9-0.7-1.7-1.5-2.5-2.3c-2-1.9-3.9-3.8-6.1-5.6c-1.3-1-2.5-1.5-3.8,0.4c-1.5,2.2-3.8,3-6.2,3.6
	c-2.7,0.7-5.5,1.5-8.4,2.2c2.9,0.4,5.6,0.9,8.4,1.5c5.6,1.3,11.1,3.4,16.1,6.4c3.2,1.9,6.4,3.8,9.6,5.8c2.8,1.8,5.4,3.9,7.7,6.4
	c3.1,3.3,5.8,7,8,11c1.9,3.8,3.4,7.7,4.3,11.9c0.3,1.7,0.5,3.5,0.6,5.2c0.1,0.7-0.3,1-1,0.7c-2.3-1.3-4.6-2.6-7-4
	c-2.6-1.7-5.1-3.5-7.5-5.4c-0.9-0.7-1.4-1.8-1.4-2.9c0-2.5,0-4.9-0.1-7.5c-0.3,0.4-0.6,0.9-0.7,1.4c-0.4,1.2-0.7,2.5-1,3.8
	c-0.3,1.3-1,1.6-2.2,0.9c-1.9-1.3-3.8-2.6-5.6-4l1.7-1.3c-1.3-2.5-2.6-4.8-3.8-7.1c-0.1-0.4-0.1-1.2-1-0.9c-0.1-0.1-0.1-0.3-0.1-0.4
	c-0.2-1.2-0.5-2.3-0.9-3.5c-0.3-0.9-0.7-1.8-1.2-2.6h-0.4c-0.5,0.6-0.9,1.2-1.3,1.9c-0.8,1.4-2,2.4-3.5,3c-0.4,0.3-0.6,0-0.9-0.4
	c-0.9-2-1.9-4.2-2.9-6.2c-1.1-1.8-2.6-3.4-4.3-4.6c-0.3,2.8-1,5.2-3.5,6.8c-2.5,1.6-5.1,1-7.7,0.4c-0.3,2,0.4,3.8,0.9,5.6
	c1.3,5.2,2.8,10.4,4.2,15.6c0.9,3.6,1.9,7.1,2.6,10.7c0.7,3.2,1.3,6.4,1.9,9.5c0.6,2.8,1,5.5,1.4,8.2c0.4,2.3,0.7,4.5,1,6.8
	c0.3,2,0.7,4,1,6.2c0.3,1.7,0.4,3.5,0.6,5.2c0.3,1.9,0.6,3.6,0.7,5.5c0.3,1.9,0.4,3.8,0.6,5.5c0.1,1.7,0.4,3.8,0.7,5.8
	c0.2,0.7,0.2,1.5,0.3,2c0.1,0.4,0.4,0.7,0.7,0.7l4.6,0.4c2.8,0.3,5.6,0.6,8.4,0.7c1.3,0.1,2.6,0.2,3.9,0.1c4.3,0.1,8.5,0.1,12.9,0.1
	c0.5,0,0.9-0.2,1.2-0.6c3.4-5.8,6.2-12,8.2-18.4c1.5-4.5,2.6-9.1,3.3-13.8c0.5-2.6,0.8-5.2,1-7.8c0.3-4.4,0.4-8.8,0.4-13.2
	c-0.1-2.5-0.3-5-0.7-7.5c-0.6-3.9-1.3-7.7-2.2-11.6c-1.1-4.7-2.6-9.2-4.5-13.6c-0.8-1.4-0.3-3.1,1-3.9c1.3-0.8,3-0.5,3.8,0.7
	c0.2,0.3,0.3,0.5,0.4,0.9c1.5,4,3,8.1,4.2,12.3c1,3.8,1.7,7.5,2.5,11.3c0.4,2.8,0.7,5.6,1,8.5c0.3,3.3,0.4,6.8,0.4,10.1
	s-0.4,6.2-0.7,9.3c-0.4,5.3-1.4,10.5-3,15.6c-1.2,4.1-2.6,8-4,11.9c-1.3,3.3-2.9,6.4-4.3,9.5c10.2-0.6,20.3-2,30.2-4.3
	c9.9-2.3,19.7-5.2,29.4-8.5c-1.2,1-2.2,2.2-3.5,3.2c-2.8,2.2-5.5,4.3-8.4,6.2c-4.7,3.2-9.7,5.9-14.9,8.2c-5.5,2.5-11.1,4.7-16.8,6.5
	c-5.6,1.7-11.4,3.2-17.2,4.3c-3.5,0.6-7,1.3-10.4,1.7c-2.2,0.3-4.3,0.4-6.5,0.6c-3.2,0.3-6.5,0.4-9.7,0.6c-4.9,0.1-9.7,0.1-14.6,0.1
	c-2.7,0-5.3-0.1-8-0.4c-2.9-0.1-5.8-0.4-8.7-0.7l-4.8-0.4c-1.9-0.1-3.9-0.4-5.8-0.7c-1.7-0.1-3.3-0.4-5.1-0.6
	c-2.3-0.3-4.8-0.7-7.1-1l-7.5-1.3c-2.8-0.4-5.6-0.9-8.4-1.5l-7.3-1.4l-10.3-1.7c-2.6-0.4-5.2-0.7-7.7-1s-5.1-0.7-7.7-1
	c-2-0.1-4-0.3-5.9-0.4c-6.1-0.1-12.2-0.4-18.2-0.3c-3.3,0-6.8,0.4-10.1,0.7c-2.5,0.3-4.9,0.4-7.5,0.7s-5.1,0.7-7.5,1.2
	c-3.9,0.7-7.8,1.6-11.7,2.6c-4.2,1-8.5,2.3-12.7,3.5c-3.9,1.1-7.7,2.5-11.6,3.8v-0.4c1.3-1.1,2.5-2.5,3.8-3.6
	c4-2.9,8.2-5.9,12.6-8.7c5.7-3.5,11.6-6.6,17.8-9.1c-0.7-1.7-1.6-3.6-2.3-5.4c-1.9-4.6-3.5-9.2-4.8-14c-1.2-4.8-2.1-9.6-2.6-14.5
	c-0.3-3.5-0.7-6.8-0.7-10.3s0.3-6.7,0.6-10c0.2-1.6,0.2-3.2,0.4-4.8c0.4-2.5,0.7-4.9,1.3-7.4c0.9-3.6,1.7-7.1,2.9-10.7
	c0.9-2.6,1.9-5.2,2.9-7.8c1.7-4.5,3.9-8.7,6.5-12.7c2-3.2,4.2-6.4,6.4-9.4c2-2.7,4.2-5.3,6.5-7.8c4-4.4,8.4-8.3,13.2-11.9
	c4.5-3.5,9.3-6.5,14.3-9.1c6.1-3.2,12.5-5.8,19.1-7.5c4.2-1.2,8.5-2,12.7-2.8c3.3-0.6,6.7-0.7,10.1-1c0.1,0,0.1-0.1,0.3-0.1
	L218.6,189.9z"
      />
      <path
        d="M232.6,388.1c-0.3,0-0.7-0.1-1-0.1c-3-0.3-6.1-0.4-9-0.7c-5.7-0.6-11.4-1.7-16.9-3.3c-4.9-1.5-9.7-3-14.5-4.6
	c-6.2-2-12.4-4.5-18.8-6.4c-5.6-1.7-11.3-3-17.1-4.3c-2.9-0.6-5.8-0.9-8.7-1.2c-3.2-0.3-6.2-0.4-9.4-0.6c-1.4-0.1-2.9-0.1-4.2-0.1
	c-2.8,0-5.7,0.1-8.5,0.4c-2.9,0.3-5.9,0.6-8.8,1c-4,0.6-8.2,1.3-12.3,2.2c-4.2,0.9-8.2,2-12.4,3c-0.1,0-0.1,0-0.4-0.1
	c1.5-1.2,2.9-2.3,4.3-3.3c5.6-3.9,11.7-6.8,18.2-8.7c3.9-1.2,7.9-2.2,12-2.9c3-0.6,6-0.9,9-1c4.6-0.3,9.4-0.4,14-0.4
	c3.6,0,7.4,0.6,11,0.9s6.8,0.9,10.1,1.4c3.3,0.6,6.5,1.3,9.8,1.9c4.5,0.9,8.8,1.9,13.3,2.9c4.9,1.2,9.8,2.3,14.9,3.5
	c6.2,1.5,12.3,2.8,18.5,4c3.2,0.7,6.5,1.2,9.8,1.6s6.4,1,9.6,1.1c4,0.3,8,0.3,12,0.1c2.2,0,4.5-0.2,6.7-0.6c3.5-0.4,7.1-1,10.4-1.7
	c4.3-1,8.5-2.2,12.7-3.5c3.3-1,6.7-2.5,9.8-3.8c0.2,0,0.4,0,0.6-0.1c-0.7,0.9-1.3,1.6-2,2.3c-4,4.2-8.7,7.7-13.9,10.4
	c-4.6,2.6-9.5,4.7-14.6,6.2c-4.2,1.2-8.4,2.2-12.6,3c-2.3,0.4-4.6,0.6-6.9,0.9l-6.4,0.4c-0.1,0-0.1,0.1-0.3,0.1L232.6,388.1z"
      />
      <path d="M735.4,249.7h7.4v78h-7.3v-40.1h-42.1v40.1h-7.2v-78h7.1v31.5h42.1V249.7z" />
      <path
        d="M468.8,249.7h7.2v52.8c0,4.2,0.9,8.3,2.8,12c1.8,3.5,4.9,6.2,8.7,7.5c4.2,1.4,8.6,1.9,13,1.4c3.5-0.2,6.8-1.2,9.8-3
	c4-2.6,6.2-6.5,7.2-11c0.6-2.5,0.9-5,0.9-7.5c0.1-16.9,0-34,0-50.9v-1.3h7.2v1c0,17.2,0.2,34.5,0,51.7c0,5.5-1.4,11-4,15.8
	c-3.2,5.6-8,9-14.2,10.7c-4.5,1.1-9.1,1.4-13.8,1c-4.8-0.2-9.4-1.6-13.5-4c-4.4-2.9-7.6-7.2-9.1-12.3c-1.1-3-1.7-6.2-1.9-9.4
	c-0.1-2.8-0.3-5.4-0.3-8.1v-45C468.9,250.6,468.8,250.1,468.8,249.7z"
      />
      <path
        d="M443.1,264.2c-1.3-0.9-2.3-1.7-3.5-2.6c-4.7-3.5-10.1-5.8-15.9-6.7c-6.6-1.1-13.5-0.2-19.7,2.3c-3.4,1.4-6.5,3.4-9.1,5.9
	c-2.9,2.7-5.3,6-7.1,9.5c-1.6,3.4-2.6,7.1-3,10.9c-0.2,2.5-0.3,5-0.3,7.5c0.3,6.5,1.7,12.7,5.5,18.2c4.3,6.3,10.6,10.9,17.9,13
	c4.1,1.1,8.4,1.5,12.6,1.1c4.2-0.3,8.3-1.3,12.1-3c3.3-1.5,6.4-3.3,9.3-5.4c0.3-0.2,0.6-0.3,0.9-0.4v7.7c0,0.4-0.2,0.9-0.6,1.1
	c-5.4,3.3-11.5,5.5-17.8,6.4c-3.4,0.4-6.8,0.6-10.3,0.4c-7.5-0.5-14.8-3.1-21-7.4c-6.1-4.2-10.8-10.3-13.5-17.2
	c-1.3-3.3-2.2-6.8-2.5-10.4c-0.4-3-0.5-6-0.3-9c0.3-7.6,2.7-14.9,6.9-21.1c3.3-4.8,7.7-8.7,12.7-11.6c4.5-2.5,9.4-4.2,14.5-4.9
	c3.6-0.4,7.1-0.6,10.7-0.4c5.1,0.2,10.1,1.5,14.6,3.9c2.2,1.2,4.3,2.6,6.4,4c0.2,0.2,0.4,0.4,0.4,0.7c0,2.3-0.1,4.5-0.1,6.8
	L443.1,264.2z"
      />
      <path d="M811.5,321v6.7h-38.6v-78h38.6v6.8h-31.4v24.2h31.4v7h-31.4v33.6C790.5,321,800.9,321,811.5,321z" />
      <path
        d="M595.2,262.2c-1.2-3.5-3.4-6.5-6.4-8.7c-3.2-2.3-7-3-10.7-3.5c-3.2-0.3-6.2-0.3-9.4-0.4h-13.3v78.1h7.1v-34.9h0.9
	c4.3,0,8.7,0,13-0.3c3.2-0.2,6.4-0.8,9.4-2c2.9-1.1,5.4-2.9,7.2-5.4c2.3-3.2,3.6-6.9,3.8-10.9C596.9,270.3,596.8,266.1,595.2,262.2z
	 M589.1,273c0,2.6-0.7,5.1-1.9,7.4c-1.4,2.2-3.5,3.8-6.1,4.5c-3.9,1.1-8.1,1.6-12.2,1.4h-6.2v-30.2h4c1.9,0,3.8,0,5.6,0.1
	c2.3,0.1,4.7,0.4,7,0.9c3.2,0.7,5.9,2.8,7.4,5.8C588.5,265.9,589.3,269.5,589.1,273z"
      />
      <path
        d="M655.8,258c-1.7,1-3.3,2-5.1,3c-0.4,0.3-0.7,0.1-1-0.3c-2.5-4.2-6.2-5.9-11-6.1c-2.7-0.2-5.4,0.4-7.7,1.7
	c-2.8,1.6-4.7,4.5-5.2,7.7c-0.9,4.1,0.5,8.4,3.7,11.3c2.9,2.8,6.2,5.2,9.8,7c4.7,2.3,9.1,5.1,13.2,8.2c3.5,2.8,5.9,6.8,6.7,11.3
	c0.8,4.6,0.6,9.4-0.7,13.9c-2.3,7.1-7.1,11.6-14.3,13.6c-3.3,0.8-6.6,1.1-10,0.7c-5.2-0.4-10.1-2.7-13.8-6.5
	c-2.3-2.6-4.1-5.5-5.3-8.7c2-1.2,4-2.2,6.2-3.3c0.3,0.6,0.5,1.2,0.7,1.9c1.3,3.4,3.7,6.2,6.8,8.1c2.1,1.2,4.5,1.9,6.9,2
	c2.7,0.3,5.4-0.1,8-1c4-1.4,7-4.7,8.1-8.8c0.7-2.3,1-4.8,0.9-7.2c-0.1-3.8-1.2-7.4-4-10c-2.2-2-4.7-3.7-7.2-5.2
	c-5.1-3-10.4-5.6-15.2-9.3c-2.9-2.3-5.5-4.9-6.8-8.5c-0.5-1.4-0.8-2.9-1-4.3c-0.6-6.5,1-12.1,6.1-16.5c3-2.4,6.6-3.9,10.4-4.3
	c3.1-0.4,6.2-0.3,9.3,0.4c4.8,1.1,8.9,4.1,11.3,8.4C655.5,257.4,655.6,257.7,655.8,258z"
      />
      <path
        d="M159.6,268.7c-0.2-0.7-0.4-1.5-0.6-2.2c-0.6-2.3-2.3-3.6-4.5-4.5c-1.9-0.7-3.9-1-5.9-1h-2.1c0.5-0.7,1.4-1.1,2.3-0.9
	c4.8-0.3,8,0.6,10.7,6.1c2-4.5,5.2-6.7,10.1-6.2c1.1,0.1,2.3,0.1,3.2,1c-2.8-0.4-5.7,0-8.2,1.3C162.2,263.8,160.7,266.1,159.6,268.7
	z"
      />
      <path
        d="M178.7,276.2c-0.1-0.2-0.2-0.5-0.1-0.7c0-1.5-1.1-2.9-2.6-3.2c-1.5-0.4-2.9-0.6-4.3-0.9c0.4-0.7,2.6-1,4-0.4
	c0.9,0.3,1.3,0.3,3.2,2.9c0.7-1.8,2.4-3.1,4.4-3.3c1.2-0.2,2.4,0.1,3.3,0.9c-3.6,0-6.4,1.2-7.5,4.9
	C178.8,276.2,178.8,276.2,178.7,276.2z"
      />
    </svg>
  )
}
